import React, { createContext, useReducer } from "react";

import { BrandAPI } from "services/interface";

export interface BrandContextInterface {
  brand: BrandProps;
  fetchingBrandDone: ((brandProps: BrandAPI) => void) | null;
}

export let Status = {
  BRAND_FETCHING: "BRAND_FETCHING",
  BRAND_READY: "BRAND_READY",
};

export interface BrandProps {
  brand: BrandAPI | null;
  status: string;
}

interface StateProps {
  brand: BrandProps;
}

enum ActionStatus {
  BRAND_FETCHING = "BRAND_FETCHING",
  BRAND_READY = "BRAND_READY",
}

export const initialState: StateProps = {
  brand: {
    brand: null,
    status: ActionStatus.BRAND_FETCHING,
  },
};

const initialUserContextState: BrandContextInterface = {
  brand: initialState.brand,
  fetchingBrandDone: null,
};

export const BrandContext = createContext<BrandContextInterface>(
  initialUserContextState
);

interface BrandAction {
  type: ActionStatus;
  payload: StateProps;
}

const brandReducer = (state: StateProps, action: BrandAction): StateProps => {
  const { type, payload } = action;
  switch (type) {
    case ActionStatus.BRAND_READY:
      return {
        ...state,
        brand: payload.brand,
      };
    default:
      return state;
  }
};

export const BrandProvider = ({ children }: any) => {
  const [brandState, brandDispatch] = useReducer(brandReducer, initialState);

  const brand = brandState.brand;

  const fetchingBrandDone = (brandProps: BrandAPI | null) =>
    brandDispatch({
      type: ActionStatus.BRAND_READY,
      payload: {
        ...brandState,
        brand: {
          ...brand,
          brand: brandProps,
          status: Status.BRAND_READY,
        },
      },
    });

  return (
    <BrandContext.Provider
      value={{
        brand,
        fetchingBrandDone,
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};

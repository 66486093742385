import { faTicketAlt, faHome, faUser, faQrcode } from "@fortawesome/free-solid-svg-icons";

const MenuBar = [
    {
        label: "Home",
        path: "/service",
        icon: faHome,
    },
    {
        label: "Reward",
        path: "/reward",
        icon: faTicketAlt,
    },
    {
        label: "Profile",
        path: "/profile",
        icon: faUser,
    },
]

const AdminMenuBar = [
    {
        label: "Home",
        path: "/admin/profile",
        icon: faHome,
    },
    {
        label: "Scan",
        path: "/admin/scan",
        icon: faQrcode,
    },
    {
        label: "Profile",
        path: "/admin/profile",
        icon: faUser,
    },
]

export {AdminMenuBar, MenuBar}
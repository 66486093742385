import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import useBrand from "stores/brand/useBrand";
import withAuth from "hoc/withAuth";
import withBrand from "hoc/withBrand";
import withLineLiff from "hoc/withLineLiff";

const Preloadgame = () => {
  const { brand } = useBrand();
  return (
    <div className="h-screen bg-red-900/50">
      <div className="flex items-center h-full text-center w-full flex flex-col justify-center items-center ">
        <div className="w-3/5 sm:w-1/5 bg-indigo-400 rounded-lg sahdow-lg py-4 self-center" style={{backgroundColor: brand?.brand?.primary_color}}>
          <div className="mb-4">
            <FontAwesomeIcon
              icon={faPlay}
              className="text-4xl text-white"
            />
          </div>
          <div className="text-center">
            <p className="text-xl text-white font-bold">
              start
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withBrand(withLineLiff(withAuth(Preloadgame)));

import { useEffect, useState } from "react";

import { BrandAPI } from "services/interface";
import BrandServices from "services/brand";
import { Status } from "stores/brand/brand";
import useBrand from "stores/brand/useBrand";

const withBrand = (WrappedComponent: any) => (props: any) => {
  const [isShow, setIsShow] = useState(false);
  const { brand, FetchingBrandDone } = useBrand();

  useEffect(() => {
    if (brand.status === Status.BRAND_FETCHING && !brand.brand) {
      BrandServices.getDetail()
        .then((res) => {
          let brandAPI: BrandAPI = res.data;
          FetchingBrandDone(brandAPI);
          setIsShow(true);
        })
        .catch((err) => {
          setIsShow(true);
        });
    } else {
      setIsShow(true);
    }
  }, []);

  if (!isShow) {
    return <div>Loading...</div>;
  }
  return <WrappedComponent {...props} />;
};

export default withBrand;

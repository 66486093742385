import { AdminMenuBar, MenuBar } from "layout.config";
import { Config, ConfigInterface } from "route.config";
import { Link, useLocation, matchPath } from "react-router-dom";
import React, { useEffect, useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useBrand from "stores/brand/useBrand";

interface layoutInterface {
  children: JSX.Element;
}

const Layout = ({ children }: layoutInterface) => {
  const { brand } = useBrand();
  let location = useLocation();
  let routesConfig: ConfigInterface = Config;
  let pathParam = useMemo(() => {
    let paths = ["/catalogdetail/:postid"];
    for (let index = 0; index < paths.length; index++) {
      if (
        matchPath(location.pathname, {
          path: paths[index],
          exact: true,
          strict: true,
        })
      ) {
        return paths[index];
      }
    }
    return "";
  }, [location.pathname]);

  let isLayout = useMemo(() => {
    let pathName = location.pathname;
    if (pathParam) {
      pathName = pathParam;
    }
    return routesConfig[pathName]?.isLayout;
  }, [routesConfig, location.pathname]);

  let isAdmin = useMemo(() => {
    if (location.pathname.split("/").length > 1) {
      return location.pathname.split("/")[1] === "admin";
    } else {
      return false;
    }
  }, [location.pathname]);

  let menuBar = useMemo(() => {
    if (isAdmin) {
      return AdminMenuBar;
    } else {
      return MenuBar;
    }
  }, [isAdmin]);

  useEffect(() => {
    console.log(isAdmin, menuBar);
    console.log(location.pathname);
  }, [isAdmin, menuBar]);
  return (
    <React.Fragment>
      <div className="relative">
        {isLayout && (
          <div className="fixed top-0 w-full">
            <div
              className="w-full text-white px-7 py-4"
              style={{ background: brand?.brand?.primary_color }}
            >
              <Link to={"/service"}>
                <div className="flex items-center">
                  <img
                    className="w-12 h-12 object-cover mr-4"
                    src={
                      brand.brand?.brand_logo ||
                      "https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                    }
                    alt="profile_image"
                  />
                  <span>{brand.brand?.brand_name}</span>
                </div>
              </Link>
            </div>
            <div
              className="text-white h-14 flex items-center justify-center text-xl"
              style={{ background: brand?.brand?.secondary_color }}
            >
              {routesConfig[pathParam || location.pathname]?.label}
            </div>
          </div>
        )}
        <div
          className={`w-screen min-h-screen bg-gray-100 ${
            isLayout && "pt-[136px] pb-[68px]"
          }`}
        >
          {children}
        </div>
        {isLayout && (
          <div className="px-7 bg-white fixed bottom-0 w-full">
            <div className="flex">
              {menuBar.map((menu, key) => {
                return (
                  <div key={key} className="flex-1 group">
                    <Link to={menu.path}>
                      <a
                        href={menu.path}
                        className={`flex items-end justify-center text-center mx-auto px-4 pt-2 w-full`}
                        style={{
                          color:
                            location.pathname === menu.path
                              ? brand?.brand?.primary_color
                              : "#9CA3AF",
                        }}
                      >
                        <span className="block px-1 pt-1 pb-1">
                          <FontAwesomeIcon
                            icon={menu.icon}
                            className="text-2xl pt-1 mb-1 mx-auto block"
                          />
                          <span className="block text-xs pb-2">
                            {menu.label}
                          </span>
                          <span
                            className={`block w-5 mx-auto h-1 ${
                              location.pathname === menu.path
                                ? "bg-indigo-500"
                                : "d-none"
                            } rounded-full`}
                            style={{
                              display:
                                location.pathname === menu.path ? "" : "none",
                              backgroundColor:
                                location.pathname === menu.path
                                  ? brand?.brand?.primary_color
                                  : "#9CA3AF",
                            }}
                          ></span>
                        </span>
                      </a>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Layout;

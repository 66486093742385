import { LineProfileProps, UserContext } from "stores/user/user";

import { UserAPI } from "services/interface";
import UserServices from "services/user";
import { useContext } from "react";

const useUser = () => {
  const { lineLiff, user, fetchingLineProfileDone, fetchingUserDone } =
    useContext(UserContext);

  const FetchingLineProfileDone = (lineProfile: LineProfileProps | null) => {
    if (fetchingLineProfileDone) fetchingLineProfileDone(lineProfile);
  };

  const FetchingUserDone = (userProfile: UserAPI | null) => {
    if (fetchingUserDone) fetchingUserDone(userProfile);
  };

  const FetchingUser = () => {
    UserServices.checkUser({ uuid: lineLiff.profile?.userId || "" })
    .then((res) => {
        let user: UserAPI = res.data;
        FetchingUserDone(user);
      })
      .catch((err) => {
        switch (err.response.status) {
          case 401:
            FetchingUserDone(null);
            break;
          default:
            break;
        }
      });
  };

  return {
    lineLiff,
    user,
    FetchingLineProfileDone,
    FetchingUserDone,
    FetchingUser
  };
};

export default useUser;

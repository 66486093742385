import Button from "components/common/button";
import { RewardModalInterface } from "pages/rewards";
import { useMemo } from "react";

interface rewardModalProps {
  rewardModal: RewardModalInterface;
  userRemainPoint: number;
  onCloseModal: () => void;
  onRedeem: () => void;
}

const RewardModal = ({
  rewardModal,
  userRemainPoint,
  onCloseModal,
  onRedeem,
}: rewardModalProps) => {
  var today = new Date().getTime();
  var rewardStartedAt = Date.parse(rewardModal.reward?.redeem_started_at || "");
  var rewardEndedAt = Date.parse(rewardModal.reward?.redeem_ended_at || "");

  let isOpenRedeem = useMemo(() => {
    return userRemainPoint >= (rewardModal.reward?.require_point || 0) &&
      (rewardModal.reward?.quantity && rewardModal.reward?.quantity  > 0) &&
      !rewardModal.isRedeemLoading &&
      today >= rewardStartedAt &&
      today <= rewardEndedAt
      ? true
      : false;
  }, [rewardModal, userRemainPoint]);

  return (
    <div
      className={`modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 ${
        rewardModal.isOpen ? "" : "hidden"
      }`}
    >
      {/* <!-- modal --> */}
      <div className="bg-white rounded shadow-lg w-10/12 md:w-1/3">
        {/* <!-- modal header --> */}
        <div className="border-b px-4 py-2 flex justify-between items-center">
          <h3 className="font-semibold text-lg">
            {rewardModal.reward?.reward_name}
          </h3>
          <button
            className={`text-black ${
              !rewardModal.isRedeemLoading
                ? "cursor-pointer"
                : "cursor-not-allowed"
            }`}
            onClick={onCloseModal}
            disabled={rewardModal.isRedeemLoading}
          >
            X
          </button>
        </div>
        {/* <!-- modal body --> */}
        <img
          src={rewardModal.reward?.reward_cover}
          alt={rewardModal.reward?.reward_name}
          className="h-60 w-full object-cover"
        />
        <div className="p-3">
          <span className="font-medium text-gray-900">คำอธิบาย: </span>
          {rewardModal.reward?.reward_description}
          <br />
          <span className="font-medium text-gray-900">คะแนน: </span>
          {rewardModal.reward?.require_point} แต้ม
          <br />
          {/* <span className="font-medium text-gray-900">สาขา: </span>
          {rewardModal.reward?.branch.branch_name} */}
        </div>
        <div className="flex justify-end items-center w-100 border-t p-3">
          <Button disabled={!isOpenRedeem} onClick={onRedeem}>
            แลกของรางวัล
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RewardModal;

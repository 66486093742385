import Button from "components/common/button";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import React from "react";
import useBrand from "stores/brand/useBrand";
import withAuth from "hoc/admin/withAuth";
import withBrand from "hoc/withBrand";
import withLineLiff from "hoc/admin/withLineLiff";

const Home = () => {
  const { brand } = useBrand();
  return (
    <React.Fragment>
      <Helmet>
        <title>Home - Atmosph CRM</title>
        <meta name="description" content="Home - Atmosph CRM" />
      </Helmet>
      <div className="h-screen w-screen bg-indigo-500 text-white flex items-center">
        <div className="w-screen text-center">
          <img
            className="w-32 h-32 object-cover mx-auto mb-4"
            src={
              brand.brand?.brand_logo ||
              "https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
            }
            alt="profile_image"
          />
          <span className="text-2xl">{brand.brand?.brand_name} (Admin)</span>
          <div className="px-7 mt-4">
            <Link to="/admin/profile">
              <Button>เมนู</Button>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withBrand(withLineLiff(withAuth(Home)));

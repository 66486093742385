import React, { useMemo, useState } from "react";

import Preloadgame from "components/game/preloadgame";
import Scratchcoupon from "components/game/scratchcoupon";
import Spin from "components/game/spin";
import TapTap from "components/game/taptap";

const Game = () => {
  const [gameType, setGameType] = useState(2);
  let GameComp = useMemo(() => {
    switch (gameType) {
      case 1:
        return <Spin />;
      case 2:
        return <Preloadgame />;
      case 3:
        return <TapTap />;
      default:
        break;
    }
  }, []);

  return <div>{GameComp}</div>;
};

export default Game;

import React, { createContext, useReducer } from "react";

import { UserAPI } from "services/interface";
import liff from "@line/liff";

export interface UserContextInterface {
  lineLiff: LineLiffProps;
  user: UserProps | null;
  fetchingUserDone: ((user: UserAPI | null) => void) | null;
  fetchingLineProfileDone:
    | ((lineProfile: LineProfileProps | null) => void)
    | null;
}

export let Status = {
  USER_FETCHING: "USER_FETCHING",
  USER_READY: "USER_READY",
  LIFF_FETCHING: "LIFF_FETCHING",
  LIFF_READY: "LIFF_READY",
};
export interface LineProfileProps {
  displayName: string;
  pictureUrl: string | null;
  userId: string;
}
export interface LineLiffProps {
  profile: LineProfileProps | null;
  status: string;
  isInLineApp: boolean;
}

export interface UserProps {
  profile: UserAPI | null;
  status: string;
}
interface StateProps {
  lineLiff: LineLiffProps;
  user: UserProps;
}

enum ActionStatus {
  USER_FETCHING = "USER_FETCHING",
  USER_READY = "USER_READY",
  LIFF_FETCHING = "LIFF_FETCHING",
  LIFF_READY = "LIFF_READY",
}

export const initialState: StateProps = {
  lineLiff: {
    profile: null,
    status: ActionStatus.LIFF_FETCHING,
    isInLineApp: liff.isInClient(),
  },
  user: {
    profile: null,
    status: ActionStatus.USER_FETCHING,
  },
};

const initialUserContextState: UserContextInterface = {
  lineLiff: initialState.lineLiff,
  user: initialState.user,
  fetchingUserDone: null,
  fetchingLineProfileDone: null,
};

export const UserContext = createContext<UserContextInterface>(
  initialUserContextState
);

interface UserAction {
  type: ActionStatus;
  payload: StateProps;
}

const userReducer = (state: StateProps, action: UserAction): StateProps => {
  const { type, payload } = action;
  switch (type) {
    case ActionStatus.USER_READY:
      return {
        ...state,
        user: payload.user,
      };
    case ActionStatus.LIFF_READY:
      return {
        ...state,
        lineLiff: payload.lineLiff,
      };
    default:
      return state;
  }
};

export const UserProvider = ({ children }: any) => {
  const [userState, userDispatch] = useReducer(userReducer, initialState);

  const lineLiff = userState.lineLiff;
  const user = userState.user;

  const fetchingLineProfileDone = (lineProfileProps: LineProfileProps | null) =>
    userDispatch({
      type: ActionStatus.LIFF_READY,
      payload: {
        ...userState,
        lineLiff: {
          ...lineLiff,
          status: Status.LIFF_READY,
          profile: lineProfileProps,
        },
      },
    });
  const fetchingUserDone = (userProfileProps: UserAPI | null) => {
    userDispatch({
      type: ActionStatus.USER_READY,
      payload: {
        ...userState,
        user: {
          ...user,
          status: Status.USER_READY,
          profile: userProfileProps,
        },
      },
    });
  };

  return (
    <UserContext.Provider
      value={{
        lineLiff,
        user,
        fetchingUserDone,
        fetchingLineProfileDone,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

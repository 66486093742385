import { Helmet } from "react-helmet";
import React, { useEffect, useMemo, useState } from "react";
import withAuth from "hoc/admin/withAuth";
import withLineLiff from "hoc/admin/withLineLiff";
import withBrand from "hoc/withBrand";
import useAdmin from "stores/admin/useAdmin";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import { MemberAPI } from "services/interface";
import AdminServices from "services/admin";
import { useParams } from "react-router-dom";
import { useQuery } from "hooks/query";

const MemberDetail = () => {
  let query = useQuery();
  //   const [member, setMember] = useState<MemberAPI | null>(null);
  const { lineLiff, admin } = useAdmin();

  useEffect(() => {
    console.log(query.get("memberNo"), lineLiff.profile?.userId);
    fetchMemberRedemptionReward();
  }, []);


  const fetchMemberRedemptionReward = async () => {
    await AdminServices.redemptionReward({
      uuid: lineLiff.profile?.userId || "",
      memberNo: query.get("memberNo") as string,
    }).then((res) => {
      let memberAPI: MemberAPI = {
        member_no: "12345678",
        name: "",
        email: "",
        tel: "",
        birthdate: null,
        total_point: 0,
        remain_point: 0,
        tier: {
          tier_name: "string;",
          tier_point: 0,
          tier_multiplier_point: 0,
        },
        point_logs: [],
        redemption_reward_logs: res.data,
      };
      console.log(res.data)
      //   setMember(memberAPI);
      //   setStep(2);
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Member Detail - Atmosph CRM</title>
        <meta name="description" content="Member Detail - Atmosph CRM" />
      </Helmet>
    </React.Fragment>
  );
};

export default withBrand(withLineLiff(withAuth(MemberDetail)));

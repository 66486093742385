import axios from "axios";

const BrandService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/brand-detail`,
});

const BrandServices = {
  getDetail: () => BrandService.get(""),
};

export default BrandServices;

import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import useUser from "stores/user/useUser";
import withAuth from "hoc/withAuth";
import withBrand from "hoc/withBrand";
import withLineLiff from "hoc/withLineLiff";

const Qrcode = () => {
  var Barcode = require('react-barcode');
  const { lineLiff, user } = useUser();  
  return (
    <>
      <Helmet>
        <title>Services - Atmosph CRM</title>
        <meta name="description" content="QRCode - Atmosph CRM" />
        <div>QRCode</div>
      </Helmet>
      <div className="bg-gray-100 w-screen min-h-screen">
        <div className="flex justify-center pt-5">
          <div className="w-10/12 bg-white rounded-xl">
            <div className="flex justify-center py-4">
              <Barcode value={user?.profile?.member_no} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withBrand(withLineLiff(withAuth(Qrcode)));

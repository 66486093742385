import { BrandContext } from "stores/brand/brand";

import { BrandAPI } from "services/interface";
import { useContext } from "react";

const useBrand = () => {
  const { brand, fetchingBrandDone } = useContext(BrandContext);

  const FetchingBrandDone = (brandProps: BrandAPI) => {
    if (fetchingBrandDone) fetchingBrandDone(brandProps);
  };

  return {
    brand,
    FetchingBrandDone,
  };
};

export default useBrand;

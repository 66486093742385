import { Config, ConfigInterface } from "route.config";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { UserAPI } from "services/interface";
import UserServices from "services/user";
import useUser from "stores/user/useUser";

const withAuth = (WrappedComponent: any) => (props: any) => {
  const [isShow, setIsShow] = useState(false);
  const { user, lineLiff, FetchingUserDone } = useUser();
  let location = useLocation();
  let pathname = useMemo(() => {
    if (location.pathname.substr(location.pathname.length - 1) === "/") {
      return location.pathname.substr(0, location.pathname.length - 1);
    } else {
      return location.pathname;
    }
  }, []);
  let history = useHistory();
  let routesConfig: ConfigInterface = Config;
  let routeConfig = routesConfig[pathname];

  useEffect(() => {
    if (routeConfig) {
      if (routeConfig.isAuth) {
        if (!user?.profile) {
          console.log("auth work at", pathname);
          console.log("LINE LIFF: ", lineLiff);
          console.log("USER: ", user);
          UserServices.checkUser({ uuid: lineLiff.profile?.userId || "" })
            .then((res) => {
              let user: UserAPI = res.data;
              FetchingUserDone(user);
              if (pathname === "/register") {
                history.push("/profile");
              } else {
                setIsShow(true);
              }
            })
            .catch((err) => {
              if (err.response && err.response.status) {
                switch (err.response.status) {
                  case 401:
                    FetchingUserDone(null);
                    if (pathname !== "/register") {
                      history.push("/register");
                    } else {
                      setIsShow(true);
                    }
                    break;
                  default:
                    break;
                }
              }
            });
        } else {
          if (pathname === "/register") {
            history.push("/profile");
          } else {
            setIsShow(true);
          }
        }
      } else {
        setIsShow(true);
      }
    } else {
      setIsShow(true);
    }
  }, []);

  if (!isShow) {
    return <div>Loading...</div>;
  }
  return <WrappedComponent {...props} />;
};

export default withAuth;

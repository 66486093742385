import { Helmet } from "react-helmet";
import React from "react";

const PageNotFound = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>404 - Atmosph CRM</title>
        <meta name="description" content="404 - Atmosph CRM" />
      </Helmet>
      <div>Page Not Found</div>
    </React.Fragment>
  );
};

export default PageNotFound;

import axios from "axios";

const RewardService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/reward`,
});

interface rewardProps {
  uuid: string;
}

interface rewardReedemProps {
  uuid: string;
  reward_id: number;
}

const RewardServices = {
  getAll: ({ uuid }: rewardProps) =>
    RewardService.get("", {
      headers: {
        Uuid: uuid,
      },
    }),
  redeem: ({ uuid, reward_id }: rewardReedemProps) =>
    RewardService.post(`/${reward_id}/redeem`, null, {
      headers: {
        Uuid: uuid,
      },
    }),
};

export default RewardServices;

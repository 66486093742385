import { useMemo } from "react";

interface InputButtonProps {
  children: any;
  style?: {
    bgColor?: string;
    textColor?: string;
  };
}

const InputSubmit = ({ children, style }: InputButtonProps) => {
  let styleProps = useMemo(() => {
    return {
      bgColor: style?.bgColor || "indigo",
      textColor: style?.textColor || "white",
    };
  }, [style]);

  return (
    <button
      type="submit"
      className={`bg-${styleProps.bgColor}-400 text-${styleProps.textColor} w-full rounded-full p-3 w-100`}
    >
      {children}
    </button>
  );
};

export default InputSubmit;

import React from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";

interface InputCheckBoxProps {
  register: UseFormRegister<FieldValues>;
  errors: {
    [x: string]: any;
  };
  name: string;
  required?: boolean;
  label?: string;
  errorMessage?: string;
}

const InputCheckBox = ({
  register,
  name,
  required,
  label,
  errors,
  errorMessage,
}: InputCheckBoxProps) => {
  return (
    <React.Fragment>
      <label className="flex justify-start items-start mb-3">
        <div className="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-indigo-500">
          <input
            id={name}
            type="checkbox"
            className="opacity-0 absolute"
            {...register(name, { required: required || false })}
          />
          <svg
            className="fill-current hidden w-4 h-4 text-indigo-500 pointer-events-none"
            viewBox="0 0 20 20"
          >
            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
          </svg>
        </div>
        <div className="select-none">
          {label}
          <br />
          {errors[name] && <span className="text-red-400">{errorMessage}</span>}
        </div>
      </label>
    </React.Fragment>
  );
};

export default InputCheckBox;

import axios from "axios";

const PostService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/post`,
});

interface postsProps {
  uuid: string;
}

interface postdetailProps {
  uuid: string;
  post_id: number;
}

const PostServices = {
  getAll: ({ uuid }: postsProps) =>
  PostService.get("", {
      headers: {
        Uuid: uuid,
      },
    }),
  getPost: ({ uuid, post_id }: postdetailProps) =>
  PostService.get(`/${post_id}`, {
      headers: {
        Uuid: uuid,
      },
    }),
};

export default PostServices;

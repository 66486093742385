import { LineProfileProps, AdminContext } from "stores/admin/admin";

import { AdminAPI } from "services/interface";
import UserServices from "services/user";
import { useContext } from "react";

const useAdmin = () => {
  const { lineLiff, admin, fetchingLineProfileDone, fetchingAdminDone } =
    useContext(AdminContext);

  const FetchingLineProfileDone = (lineProfile: LineProfileProps | null) => {
    if (fetchingLineProfileDone) fetchingLineProfileDone(lineProfile);
  };

  const FetchingAdminDone = (adminProfile: AdminAPI | null) => {
    if (fetchingAdminDone) fetchingAdminDone(adminProfile);
  };

  const FetchingAdmin = () => {
    UserServices.checkUser({ uuid: lineLiff.profile?.userId || "" })
    .then((res) => {
        let admin: AdminAPI = res.data;
        FetchingAdminDone(admin);
      })
      .catch((err) => {
        switch (err.response.status) {
          case 401:
            FetchingAdminDone(null);
            break;
          default:
            break;
        }
      });
  };

  return {
    lineLiff,
    admin,
    FetchingLineProfileDone,
    FetchingAdminDone,
    FetchingAdmin
  };
};

export default useAdmin;

import { InputCheckBox, InputSubmit } from "components/common/form";

import AdminServices from "services/admin";
import { Helmet } from "react-helmet";
import InputPassword from "components/common/form/InputPassword";
import InputText from "components/common/form/InputText";
import React from "react";
import Swal from "sweetalert2";
import useAdmin from "stores/admin/useAdmin";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import withAuth from "hoc/admin/withAuth";
import withBrand from "hoc/withBrand";
import withLineLiff from "hoc/admin/withLineLiff";

const Register = () => {
  const { lineLiff, admin } = useAdmin();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  let history = useHistory();

  const onRegister = (data: any) => {
    if (!admin?.profile) {
      console.log(lineLiff);
      AdminServices.login({ uuid: lineLiff.profile?.userId || "", data })
        .then((res) => {
          history.push("/admin/profile");
        })
        .catch((err) => {
          switch (err.response.status) {
            case 409:
              history.push("/admin/profile");
              break;
            default:
              Swal.fire({
                icon: "error",
                title: err.response.status,
                text: `(${err.response.data.status_code})`,
              });
              break;
          }
        });
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Login - Atmosph CRM</title>
        <meta name="description" content="Register - Atmosph CRM" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 bg-opacity-75 text-center">
        <div className="container mx-auto">
          <h1 className="text-3xl font-medium text-gray-900 title-font container pt-12 pb-3 mx-auto text-center md:text-left">
            เข้าสู่ระบบ
          </h1>
          <div className="mt-12">
            <img
              className="w-32 h-32 object-cover rounded-full mx-auto"
              src={
                lineLiff.profile?.pictureUrl ||
                "https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
              }
              alt="profile_image"
            />
            <h1 className="text-2xl font-medium text-gray-900 title-font container pt-3 pb-3 mx-auto text-center md:text-left">
              {lineLiff.profile?.displayName}
            </h1>
          </div>
          <form onSubmit={handleSubmit(onRegister)}>
            <div className="px-7">
              <InputText
                register={register}
                watch={watch}
                setValue={setValue}
                errors={errors}
                name="email"
                required={true}
                label="อีเมล"
                errorMessage="กรุณากรอกอีเมล"
                autoFocus={true}
              />
              <InputPassword
                register={register}
                watch={watch}
                errors={errors}
                name="password"
                required={true}
                label="รหัสผ่าน"
                errorMessage="กรุณากรอกรหัสผ่าน"
              />
            </div>

            <div className="px-7 py-12 fixed bottom-0 w-screen text-left">
              <InputCheckBox
                register={register}
                errors={errors}
                name="confirmRegister"
                required={true}
                label="ยินยอมให้ทางระบบเก็บข้อมูลที่ได้จาก LINE Application"
                errorMessage="กรุณายืนยัน"
              />
              <InputSubmit>สมัครสมาชิก</InputSubmit>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withBrand(withLineLiff(withAuth(Register)));

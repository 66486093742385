import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PostDetailAPI } from "services/interface"
import PostService from "services/post"
import Slider from "react-slick";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import useBrand from "stores/brand/useBrand";
import useUser from "stores/user/useUser";
import withBrand from "hoc/withBrand";

const CatalogDetail = () => {
  let param = useParams() as any;
  const {brand} = useBrand()
  const {user, lineLiff} = useUser()
  const [post, setPost] = useState<PostDetailAPI>()
  const [animatedetail, setAnimatedetail] = useState<boolean>(false)
  const [showdetail, setShowdetail] = useState<boolean>(false)
  const [firstcircle, setFirstcircle] = useState<boolean>(true)
  const [firsttime, setFirsttime] = useState<boolean>(true)
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: () => setShowdetail(false),
  };

  useEffect(() => {
    PostService.getPost({uuid: lineLiff.profile?.userId || "", post_id: param?.postid}).then((res) => {
      setPost(res.data)
    })
  }, [])

  const updateShowDetail = (status : boolean) => {
    if(firsttime) {
      setFirstcircle(false)
      setTimeout(() => {
        setFirsttime(false)
        setAnimatedetail(status)
        if(!status) {
          setTimeout(() => setShowdetail(status), 0.5 * 1000)
        }else
        setShowdetail(status)
      }, 0.5 * 1000)
    }else {
      setAnimatedetail(status)
      if(!status) {
        setTimeout(() => setShowdetail(status), 0.5 * 1000)
      }else
      setShowdetail(status)
    }
  } 

  return (
    <div className="py-4 w-full">
      {post && 
        <Slider {...settings}>
          {post?.pictures.map((picture) => {
            return (
              <div>
                <div className="relative w-full" onClick={()=>{updateShowDetail(!showdetail)}}>
                  {picture.tags.length !== 0 && picture.tags.map((tag) => {
                    return (
                      <>
                        <div style={{display: firsttime ? "" : "none"}} className={`animate__animated animate__faster ${
                          firstcircle ? "animate__fadeIn" : "animate__fadeOut"
                        }`} >
                          <div className="rounded-full shadow-xl bg-white p-4" style={{position: "absolute", left: `${tag.position_x}%`, top: `${tag.position_y}%`, zIndex: 20, transform: "translateX(-50%)"}}/>
                        </div>
                        <div className={`animate__animated animate__faster ${
                          animatedetail ? "animate__fadeIn" : "animate__fadeOut" 
                        }`} 
                        style={{display: showdetail ? "" : "none", position: "absolute", left: `${tag.position_x}%`, top: `${tag.position_y}%`, zIndex: 20}}>
                          <div className="arrow-up" style={{transform: "translateX(-50%)"}}/>
                          <div className="bg-gray-500 text-white drop-shadow-lg rounded-xl px-3 py-1" style={{transform: "translateX(-50%)"}}>
                            <a href={tag.link_url}><span>{tag.name} <FontAwesomeIcon icon={faChevronRight} /></span></a>
                          </div>
                        </div>
                      </>
                    )
                  })}
                  <img src={picture.image_url} ></img>
                </div>
              </div>
            )
          })}
        </Slider>
      }
    </div>
  );
};

export default withBrand(CatalogDetail);

import { Config, ConfigInterface } from "route.config";
import { useEffect, useMemo, useState } from "react";

import { LineProfileProps } from "stores/user/user";
import liff from "@line/liff";
import { useLocation } from "react-router-dom";
import { useQuery } from "hooks/query";
import useUser from "stores/user/useUser";

const withLineLiff = (WrappedComponent: any) => (props: any) => {
  const [isShow, setIsShow] = useState(false);
  const { user, lineLiff, FetchingLineProfileDone } = useUser();
  let location = useLocation();
  let pathname = useMemo(() => {
    if (location.pathname.substr(location.pathname.length - 1) === "/") {
      return location.pathname.substr(0, location.pathname.length - 1);
    } else {
      return location.pathname;
    }
  }, []);
  let query = useQuery();
  let ONLY_LINE_APP =
    process.env.REACT_APP_ONLY_LINE_APP === "yes" ? true : false;
  let routesConfig: ConfigInterface = Config;
  let routeConfig = routesConfig[pathname];
  let liffId = process.env.REACT_APP_LIFF_ID || "";

  useEffect(() => {
    if (routeConfig) {
      if ((pathname === "/" || pathname === "") && query.get("liff.state")) {
        liff.init({ liffId: liffId });
      } else {
        if (!lineLiff.profile) {
          if (routeConfig.isLineAuth) {
            console.log("liff work at", pathname);
            console.log("LINE LIFF: ", lineLiff);
            console.log("USER: ", user);
            //====================for use=====================
            // liff.init({ liffId: liffId }).then(async () => {
            //   if (!lineLiff.profile) {
            //     if (liff.isLoggedIn()) {
            //       const profile = await liff.getProfile();
            //       let lineProfile: LineProfileProps = {
            //         displayName: profile.displayName,
            //         pictureUrl: profile.pictureUrl || null,
            //         userId: profile.userId,
            //       };
            //       FetchingLineProfileDone(lineProfile);
            //       setIsShow(true);
            //     } else {
            //       liff.login({
            //         redirectUri: `${process.env.REACT_APP_DOMAIN}${pathname}`,
            //       });
            //     }
            //   }
            // });
            //====================for use=====================

            //====================for test====================
            let lineProfile: LineProfileProps = {
              displayName: "P.",
              pictureUrl:
                "https://profile.line-scdn.net/0hy_saX0lzJnBOGDF8nwhZJ3JdKB05NiA4NnpsQTscfEE2fGkuJ31vQmxNcUFkIGIkcC05QW1KfUNr",
              userId: "U08b24a037be3f34a1f9be4a4932a2b8e",
            };
            FetchingLineProfileDone(lineProfile);
            setIsShow(true);
            //====================for test====================
          } else {
            FetchingLineProfileDone(lineLiff.profile);
            setIsShow(true);
          }
        } else {
          FetchingLineProfileDone(lineLiff.profile);
          setIsShow(true);
        }
      }
    } else {
      setIsShow(true);
    }
  }, []);

  if (ONLY_LINE_APP && !lineLiff.isInLineApp) {
    return <div>Please open in LINE Application</div>;
  }
  if (!isShow) {
    return <div>Loading...</div>;
  }
  return <WrappedComponent {...props} />;
};

export default withLineLiff;

import React, { useMemo, useState } from "react";

const Spin = () => {
  let [selectedItem, setSelectedItem] = useState<any>(null);
  let [items, setItems] = useState<string[]>([
    "Pizzas",
    "Sandwiches",
    "Salads",
    "Soup",
    "Japanese food",
    "Pastas",
  ]);
  const wheelVars: any = useMemo(() => {
    return {
      "--nb-item": items.length,
      "--selected-item": selectedItem,
    };
  }, [selectedItem, items]);
  const spinning = useMemo(() => {
    if (selectedItem) {
      return "spinning";
    }
    return "";
  }, [selectedItem]);

  const selectItem = () => {
    // if (selectedItem === null) {
    const selectedItemData = 5;
    setSelectedItem(selectedItemData);
    // } else {
    //     console.log("asasd");
    //   setSelectedItem(null);
    // //   setTimeout(selectItem, 500);
    // }
  };

  return (
    <div className="wheel-container">
      <div
        className={`wheel ${spinning}`}
        style={wheelVars}
        onClick={selectItem}
      >
        {items.map((item, index) => {
          let styleProps: any = {
            "--item-nb": index,
          };
          return (
            <div className="wheel-item" key={index} style={styleProps}>
              <div className="inline-block">
                <span>{item}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Spin;

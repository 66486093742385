import React, { useEffect, useMemo, useState } from "react";

const TapTap = () => {
  const [isTap, setIsTap] = useState(false);
  const [hp, setHp] = useState(100);
  let fullHp = 100;

  useEffect(() => {
    console.log(hp);
  }, [hp]);

  const onTap = () => {
    if (!isTap && hp) {
      setIsTap(true);
      setHp(hp - 10);
      setTimeout(() => {
        setIsTap(false);
      }, 500);
    }
  };

  return (
    <div>
      <div className="relative">
        <div className="w-full h-1 bg-gray-500" />
        <div
          className="absolute top-0 h-1 bg-red-500"
          style={{ width: (hp / fullHp) * 100 + "%" }}
        />
      </div>
      <img
        alt="item"
        className={`${isTap ? "shake-item" : ""}`}
        onClick={onTap}
        width="300"
        src="https://img.ltwebstatic.com/images3_pi/2021/12/06/163877544572d789a8b547dcf8a849961c60515d14_thumbnail_900x.webp"
      />
    </div>
  );
};

export default TapTap;

import {
  faBook,
  faCrown,
  faHistory,
  faTicketAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const Services = [
  {
    label: "Profile",
    path: "/profile",
    icon: faUser,
  },
  {
    label: "Reward",
    path: "/reward",
    icon: faTicketAlt,
  },
  {
    label: "Tier",
    path: "/tier",
    icon: faCrown,
  },
  {
    label: "Redemption",
    path: "/redemption",
    icon: faHistory,
  },
  {
    label: "Menu",
    path: "/menu",
    icon: faBook,
  },
  {
    label: "Catalog",
    path: "/catalog",
    icon: faHistory,
  },
];

export { Services };

import { RewardModalInterface } from "pages/redemtionlog";

interface rewardModalProps {
  rewardModal: RewardModalInterface;
  onCloseModal: () => void;
}

const RewardModal = ({
  rewardModal,
  onCloseModal
}: rewardModalProps) => {
  console.log(rewardModal)
  return (
    <div
      className={`modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 ${
        rewardModal.isOpen ? "" : "hidden"
      }`}
    >
      {/* <!-- modal --> */}
      <div className="bg-white rounded shadow-lg w-10/12 md:w-1/3">
        {/* <!-- modal header --> */}
        <div className="border-b px-4 py-2 flex justify-between items-center">
          <h3 className="font-semibold text-lg">
            {rewardModal.reward?.reward.reward_name}
          </h3>
          <button
            className={`text-black ${
              !rewardModal.isRedeemLoading
                ? "cursor-pointer"
                : "cursor-not-allowed"
            }`}
            onClick={onCloseModal}
            disabled={rewardModal.isRedeemLoading}
          >
            X
          </button>
        </div>
        {/* <!-- modal body --> */}
        <img
          src={rewardModal.reward?.reward.reward_cover}
          alt={rewardModal.reward?.reward.reward_name}
          className="h-60 w-full object-cover"
        />
        <div className="p-3">
          <span className="font-medium text-gray-900">คำอธิบาย: </span>
          {rewardModal.reward?.reward.reward_description}
          <br />
          <span className="font-medium text-gray-900">คะแนน: </span>
          {rewardModal.reward?.reward.require_point} แต้ม
          <br />
          {/* <span className="font-medium text-gray-900">สาขา: </span>
          {rewardModal.reward?.reward.branch.branch_name} */}
        </div>
        <div className="flex justify-end items-center w-100 border-t p-3">
          <button
            className={`bg-${
              !rewardModal.isRedeemLoading ? "red" : "gray"
            }-600 hover:bg-${
              !rewardModal.isRedeemLoading ? "red" : "gray"
            }-700 px-3 py-1 rounded text-white mr-1 ${
              !rewardModal.isRedeemLoading
                ? "cursor-pointer"
                : "cursor-not-allowed"
            }`}
            onClick={onCloseModal}
            disabled={rewardModal.isRedeemLoading}
          >
            ปิด
          </button>
        </div>
      </div>
    </div>
  );
};

export default RewardModal;

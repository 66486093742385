import React from "react";
import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";

interface InputPasswordProps {
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  errors: {
    [x: string]: any;
  };
  name: string;
  required?: boolean;
  label?: string;
  errorMessage?: string;
  autoFocus?: boolean;
}

const InputPassword = ({
  register,
  watch,
  name,
  required,
  label,
  errors,
  errorMessage,
  autoFocus
}: InputPasswordProps) => {
  return (
    <React.Fragment>
      <div className="mb-4 relative">
        <input
          className={`${
            watch(name) && "filled"
          } input border border-gray-400 appearance-none rounded w-full px-3 py-3 pt-5 pb-2 focus focus:border-indigo-600 focus:outline-none active:outline-none active:border-indigo-600`}
          id={name}
          type="password"
          {...register(name, { required: required || false })}
          autoFocus={autoFocus}
        />
        <label
          htmlFor={name}
          className="label absolute mb-0 -mt-2 pl-3 leading-tighter text-gray-400 text-base mt-2 cursor-text"
        >
          {label}
        </label>
        <br />
        {errors[name] && <div className="text-left"><span className="text-red-400 ">{errorMessage}</span></div>}
      </div>
    </React.Fragment>
  );
};

export default InputPassword;

// import "./App.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'animate.css';

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Catalogs from "pages/catalogs";
import CatalogsDetail from "pages/catalogdetail";
import ContextProvider from "stores/ProviderComposer";
import EditProfile from "pages/editProfile";
import Home from "pages";
import HomeAdmin from "pages/admin";
import Layout from "components/layout";
import LoginAdmin from "pages/admin/login";
import PageNotFound from "pages/404";
import Profile from "pages/profile";
import ProfileAdmin from "pages/admin/profile";
import Qrcode from "pages/qrcode";
import Redemption from "pages/redemtionlog";
import Register from "pages/register";
import Rewards from "pages/rewards";
import Scan from "pages/admin/scan";
import Services from "pages/services";
import Tier from "pages/tier";
import Game from "pages/game";

import VConsole from "vconsole";
import MemberDetail from "pages/admin/memberDetail";
import Menu from "pages/menu";

new VConsole();

const App = () => {
  return (
    <ContextProvider>
      <div className="font-kanit">
        <Router>
          <Layout>
            <Switch>
              <Route exact path="/admin">
                <HomeAdmin />
              </Route>
              <Route exact path="/admin/login">
                <LoginAdmin />
              </Route>
              <Route exact path="/admin/profile">
                <ProfileAdmin />
              </Route>
              <Route exact path="/admin/scan">
                <Scan />
              </Route>
              <Route exact path="/admin/member-detail">
                <MemberDetail />
              </Route>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/register">
                <Register />
              </Route>
              <Route exact path="/profile">
                <Profile />
              </Route>
              <Route exact path="/profile/edit">
                <EditProfile />
              </Route>
              <Route exact path="/tier">
                <Tier />
              </Route>
              <Route exact path="/reward">
                <Rewards />
              </Route>
              <Route exact path="/service">
                <Services />
              </Route>
              <Route exact path="/redemption">
                <Redemption />
              </Route>
              <Route exact path="/qrcode">
                <Qrcode />
              </Route>
              <Route exact path="/catalog">
                <Catalogs />
              </Route>
              <Route exact path="/menu">
                <Menu />
              </Route>
              <Route exact path="/catalogdetail/:postid">
                <CatalogsDetail />
              </Route>
              <Route exact path="/game/:code">
                <Game />
              </Route>
              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          </Layout>
        </Router>
      </div>
    </ContextProvider>
  );
};

export default App;

export interface ConfigInterface {
  [key: string]: {
    label: string;
    isLineAuth: boolean;
    isAuth: boolean;
    isLayout: boolean;
  };
}

const Config = {
  "/admin": {
    label: "",
    isLineAuth: false,
    isAuth: false,
    isLayout: false,
  },
  "/admin/profile": {
    label: "โปรไฟล์",
    isLineAuth: true,
    isAuth: true,
    isLayout: true,
  },
  "/admin/login": {
    label: "เข้าสู่ระบบ",
    isLineAuth: true,
    isAuth: true,
    isLayout: false,
  },
  "/admin/scan": {
    label: "Scan",
    isLineAuth: true,
    isAuth: true,
    isLayout: true,
  },
  "/admin/member-detail": {
    label: "Member Detail",
    isLineAuth: true,
    isAuth: true,
    isLayout: true,
  },
  "/": {
    label: "",
    isLineAuth: false,
    isAuth: false,
    isLayout: false,
  },
  "": {
    label: "",
    isLineAuth: false,
    isAuth: false,
    isLayout: false,
  },
  "/register": {
    label: "สมัครสมาชิก",
    isLineAuth: true,
    isAuth: true,
    isLayout: false,
  },
  "/profile": {
    label: "โปรไฟล์",
    isLineAuth: true,
    isAuth: true,
    isLayout: true,
  },
  "/profile/edit": {
    label: "แก้ไขโปรไฟล์",
    isLineAuth: true,
    isAuth: true,
    isLayout: true,
  },
  "/reward": {
    label: "ของรางวัล",
    isLineAuth: true,
    isAuth: true,
    isLayout: true,
  },
  "/service": {
    label: "บริการ",
    isLineAuth: true,
    isAuth: false,
    isLayout: true,
  },
  "/tier": {
    label: "ระดับสมาชิก",
    isLineAuth: true,
    isAuth: false,
    isLayout: true,
  },
  "/redemption": {
    label: "ประวัติการแลกของรางวัล",
    isLineAuth: true,
    isAuth: true,
    isLayout: true,
  },
  "/qrcode": {
    label: "QRCode",
    isLineAuth: true,
    isAuth: true,
    isLayout: true,
  },
  "/catalog": {
    label: "Catalog",
    isLineAuth: false,
    isAuth: false,
    isLayout: true,
  },
  "/menu": {
    label: "Menu",
    isLineAuth: false,
    isAuth: false,
    isLayout: true,
  },
  "/catalogdetail/:postid": {
    label: "CatalogDetail",
    isLineAuth: false,
    isAuth: false,
    isLayout: true,
  },
};

export { Config };

import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import CRMFile from "components/menu/CRM.pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Slider from "react-slick";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PDF = () => {
  const [numPages, setNumPages] = useState(null);
  const [PDFWidth, setPDFWidth] = useState(window.innerWidth);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    const resizeHandler = () => {
      const currentWindowWidth = window.innerWidth;
      setPDFWidth(currentWindowWidth);
    };
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, [window.innerWidth]);

  useEffect(() => {
    console.log(PDFWidth);
  }, [PDFWidth]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <Document
        file={CRMFile}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
      >
        <div className="pb-[30px]">
          <Slider {...settings}>
            {Array.from(Array(numPages), (e, i) => {
              return <Page pageNumber={i + 1} width={PDFWidth} />;
            })}
          </Slider>
        </div>
      </Document>
      <span></span>
    </>
  );
};

export default PDF;

import axios from "axios";

const AdminService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/admin`,
});

interface adminProps {
  uuid: string;
}

interface adminLoginProps {
  uuid: string;
  data: {
    email: string;
    password: string;
  };
}

interface adminRedemptionRewardProps {
  uuid: string;
  memberNo: string;
}

interface adminStampRedemptionRewardProps {
  uuid: string;
  code: string;
}

const AdminServices = {
  checkAdmin: ({ uuid }: adminProps) =>
    AdminService.get("", {
      headers: {
        Uuid: uuid,
      },
    }),
  login: ({ uuid, data }: adminLoginProps) =>
    AdminService.post("/login", data, {
      headers: {
        Uuid: uuid,
      },
    }),
  redemptionReward: ({ uuid, memberNo }: adminRedemptionRewardProps) =>
    AdminService.get(`/redemption-reward?member_no=${memberNo}`, {
      headers: {
        Uuid: uuid,
      },
    }),
  stampRedemptionReward: ({ uuid, code }: adminStampRedemptionRewardProps) =>
    AdminService.post(`/redemption-reward/${code}/stamp`, {}, {
      headers: {
        Uuid: uuid,
      },
    }),
};

export default AdminServices;

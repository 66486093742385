import React, { cloneElement } from "react";

import { UserProvider } from "stores/user/user";
import { AdminProvider } from "stores/admin/admin";
import { BrandProvider } from "stores/brand/brand";

interface ProviderComposerProps {
  contexts: any;
  children: any;
}
function ProviderComposer({ contexts, children }: ProviderComposerProps) {
  return contexts.reduce(
    (kids: any, parent: any) =>
      cloneElement(parent, {
        children: kids,
      }),
    children
  );
}

interface ContextProviderProps {
  children: any;
}

export default function ContextProvider({ children }: ContextProviderProps) {
  return (
    <ProviderComposer
      // add providers to array of contexts
      contexts={[
        <UserProvider key="user" />,
        <BrandProvider key="brand" />,
        <AdminProvider key="admin" />,
      ]}
    >
      {children}
    </ProviderComposer>
  );
}

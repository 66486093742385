import "dayjs/locale/th";

import React, { useEffect, useState } from "react";

import Button from "components/common/button";
import { Helmet } from "react-helmet";
import { RewardAPI } from "services/interface";
import RewardModal from "components/reward/rewardModal";
import RewardServices from "services/reward";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import useUser from "stores/user/useUser";
import withAuth from "hoc/withAuth";
import withBrand from "hoc/withBrand";
import withLineLiff from "hoc/withLineLiff";

export interface RewardModalInterface {
  reward: RewardAPI | null;
  isOpen: boolean;
  isRedeemLoading: boolean;
}

const Rewards = () => {
  const [rewards, setRewards] = useState<RewardAPI[] | null>(null);
  const [rewardModal, setRewardModal] = useState<RewardModalInterface>({
    reward: null,
    isOpen: false,
    isRedeemLoading: false,
  });
  const { lineLiff, user, FetchingUser } = useUser();

  useEffect(() => {
    RewardServices.getAll({ uuid: lineLiff.profile?.userId || "" })
      .then((res) => {
        setRewards(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const onOpenModal = (index: number) => {
    setRewardModal({
      ...rewardModal,
      reward: rewards ? rewards[index] : null,
      isOpen: true,
    });
  };

  const onCloseModal = () => {
    setRewardModal({
      reward: null,
      isOpen: false,
      isRedeemLoading: false,
    });
  };

  const onRedeem = () => {
    Swal.fire({
      title: "ต้องการแลกของรางวัลชิ้นนี้ ใช่หรือไม่?",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: `ยกเลิก`,
    }).then((result) => {
      if (result.isConfirmed) {
        setRewardModal({
          ...rewardModal,
          isRedeemLoading: true,
        });
        RewardServices.redeem({
          uuid: lineLiff.profile?.userId || "",
          reward_id: rewardModal.reward?.id || 0,
        })
          .then((res) => {
            FetchingUser();
            setRewardModal({
              ...rewardModal,
              isRedeemLoading: false,
            });
            Swal.fire("แลกของรางวัลสำเร็จ", "", "success");
          })
          .catch((err) => {
            setRewardModal({
              ...rewardModal,
              isRedeemLoading: false,
            });
            Swal.fire({
              icon: "error",
              title: "แลกของรางวัลไม่สำเร็จ",
              text: `(${err.response.data.status_code})`,
            });
          });
      }
    });
  };

  let rewardModalProps = {
    rewardModal,
    userRemainPoint: user?.profile?.remain_point || 0,
    onCloseModal,
    onRedeem,
  };
  return (
    <>
      <Helmet>
        <title>Rewards - Atmosph CRM</title>
        <meta name="description" content="Rewards - Atmosph CRM" />
      </Helmet>
      <section className="bg-gray-100 text-gray-600 w-screen">
        <h2 className="text-xl font-medium text-gray-900 pt-3 text-center">
          แต้มของคุณมี {user?.profile?.remain_point} แต้ม
        </h2>
        <RewardModal {...rewardModalProps} />
        <div className="px-5 mx-auto">
          <div className="divide-y-2 divide-gray-200">
            {rewards ? (
              <React.Fragment>
                {rewards.length > 0 ? (
                  <React.Fragment>
                    {rewards.map((reward, key) => {
                      return (
                        <>
                          <div
                            key={reward.id}
                            className="bg-white rounded-lg mx-auto py-3 mt-4"
                            onClick={() => onOpenModal(key)}
                          >
                            <div className="flex">
                              <div className="w-2/6 flex items-center justify-center">
                                <img
                                  src={reward.reward_image}
                                  className="md:w-3/6 w-4/6 h-auto"
                                  alt={reward.reward_name}
                                />
                              </div>
                              <div className="w-4/6 flex items-center">
                                <div>
                                  <h2 className="text-2xl font-medium text-gray-900 title-font">
                                    {reward.reward_name}
                                  </h2>
                                  <p className="leading-relaxed text-gray-900">
                                    {reward.reward_description}
                                  </p>
                                  <p className="leading-relaxed text-sm text-gray-500">
                                    หมดเขต{" "}
                                    {dayjs(reward.redeem_ended_at)
                                      .locale("th")
                                      .add(543, "year")
                                      .format("DD MMMM YYYY")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <div>ไม่มีของรางวัล</div>
                )}
              </React.Fragment>
            ) : (
              <div>Loading Reward ....</div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default withBrand(withLineLiff(withAuth(Rewards)));

import { useMemo } from "react";

interface ButtonProps {
  onClick?: () => void;
  children: any;
  style?: {
    bgColor?: string;
    textColor?: string;
  };
  disabled?: boolean;
}

const Button = ({ onClick, children, style, disabled }: ButtonProps) => {
  let styleProps = useMemo(() => {
    return {
      bgColor: !disabled ? (style?.bgColor || "indigo") : "gray",
      textColor: style?.textColor || "white",
    };
  }, [style, disabled]);

  return (
    <button
      className={`bg-${styleProps.bgColor}-400 text-${styleProps.textColor} w-full rounded-full p-3 w-100`}
      onClick={onClick}
      disabled={disabled || false}
    >
      {children}
    </button>
  );
};

export default Button;

import axios from "axios";

const TierService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/user-tier`,
});

interface tierProps {
  uuid: string;
}

const TierServices = {
  getAll: ({ uuid }: tierProps) =>
    TierService.get("", {
      headers: {
        Uuid: uuid,
      },
    }),
};

export default TierServices;

import React, { createContext, useReducer } from "react";

import { AdminAPI } from "services/interface";
import liff from "@line/liff";

export interface AdminContextInterface {
  lineLiff: LineLiffProps;
  admin: AdminProps | null;
  fetchingAdminDone: ((admin: AdminAPI | null) => void) | null;
  fetchingLineProfileDone:
    | ((lineProfile: LineProfileProps | null) => void)
    | null;
}

export let Status = {
  ADMIN_FETCHING: "ADMIN_FETCHING",
  ADMIN_READY: "ADMIN_READY",
  LIFF_FETCHING: "LIFF_FETCHING",
  LIFF_READY: "LIFF_READY",
};

export interface LineProfileProps {
  displayName: string;
  pictureUrl: string | null;
  userId: string;
}

export interface LineLiffProps {
  profile: LineProfileProps | null;
  status: string;
  isInLineApp: boolean;
}

export interface AdminProps {
  profile: AdminAPI | null;
  status: string;
}

interface StateProps {
  lineLiff: LineLiffProps;
  admin: AdminProps;
}

enum ActionStatus {
  ADMIN_FETCHING = "ADMIN_FETCHING",
  ADMIN_READY = "ADMIN_READY",
  LIFF_FETCHING = "LIFF_FETCHING",
  LIFF_READY = "LIFF_READY",
}

export const initialState: StateProps = {
  lineLiff: {
    profile: null,
    status: ActionStatus.LIFF_FETCHING,
    isInLineApp: liff.isInClient(),
  },
  admin: {
    profile: null,
    status: ActionStatus.ADMIN_FETCHING,
  },
};

const initialUserContextState: AdminContextInterface = {
  lineLiff: initialState.lineLiff,
  admin: initialState.admin,
  fetchingAdminDone: null,
  fetchingLineProfileDone: null,
};

export const AdminContext = createContext<AdminContextInterface>(
  initialUserContextState
);

interface UserAction {
  type: ActionStatus;
  payload: StateProps;
}

const adminReducer = (state: StateProps, action: UserAction): StateProps => {
  const { type, payload } = action;
  switch (type) {
    case ActionStatus.ADMIN_READY:
      return {
        ...state,
        admin: payload.admin,
      };
    case ActionStatus.LIFF_READY:
      return {
        ...state,
        lineLiff: payload.lineLiff,
      };
    default:
      return state;
  }
};

export const AdminProvider = ({ children }: any) => {
  const [adminState, adminDispatch] = useReducer(adminReducer, initialState);

  const lineLiff = adminState.lineLiff;
  const admin = adminState.admin;

  const fetchingLineProfileDone = (lineProfileProps: LineProfileProps | null) =>
    adminDispatch({
      type: ActionStatus.LIFF_READY,
      payload: {
        ...adminState,
        lineLiff: {
          ...lineLiff,
          status: Status.LIFF_READY,
          profile: lineProfileProps,
        },
      },
    });
  const fetchingAdminDone = (adminProfileProps: AdminAPI | null) => {
    adminDispatch({
      type: ActionStatus.ADMIN_READY,
      payload: {
        ...adminState,
        admin: {
          ...admin,
          status: Status.ADMIN_READY,
          profile: adminProfileProps,
        },
      },
    });
  };

  return (
    <AdminContext.Provider
      value={{
        lineLiff,
        admin,
        fetchingAdminDone,
        fetchingLineProfileDone,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

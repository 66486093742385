import { Helmet } from "react-helmet";
import React from "react";
import UserServices from "services/user";
import { useHistory } from "react-router-dom";
import useUser from "stores/user/useUser";
import withAuth from "hoc/withAuth";
import withLineLiff from "hoc/withLineLiff";
import { InputSubmit, InputCheckBox } from "components/common/form";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import withBrand from "hoc/withBrand";

const Register = () => {
  const { lineLiff, user } = useUser();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  let history = useHistory();

  const onRegister = () => {
    if (!user?.profile) {
      console.log(lineLiff);
      UserServices.register({ uuid: lineLiff.profile?.userId || "" })
        .then((res) => {
          history.push("/profile");
        })
        .catch((err) => {
          switch (err.response.status) {
            case 409:
              history.push("/profile");
              break;
            default:
              Swal.fire({
                icon: 'error',
                title: err.response.status,
                text: `(${err.response.data.status_code})`,
              })
              break;
          }
        });
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Register - Atmosph CRM</title>
        <meta name="description" content="Register - Atmosph CRM" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 bg-opacity-75 text-center">
        <div className="container mx-auto">
          <h1 className="text-3xl font-medium text-gray-900 title-font container pt-12 pb-3 mx-auto text-center md:text-left">
            สมัครสมาชิก
          </h1>
          <div className="mt-12">
            <img
              className="w-32 h-32 object-cover rounded-full mx-auto"
              src={
                lineLiff.profile?.pictureUrl ||
                "https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
              }
              alt="profile_image"
            />
            <h1 className="text-2xl font-medium text-gray-900 title-font container pt-3 pb-3 mx-auto text-center md:text-left">
              {lineLiff.profile?.displayName}
            </h1>
          </div>
          <div className="px-7 py-12 fixed bottom-0 w-screen text-left">
            <form onSubmit={handleSubmit(onRegister)}>
              <InputCheckBox
                register={register}
                errors={errors}
                name="confirmRegister"
                required={true}
                label="ยินยอมให้ทางระบบเก็บข้อมูลที่ได้จาก LINE Application"
                errorMessage="กรุณายืนยัน"
              />
              <InputSubmit>สมัครสมาชิก</InputSubmit>
            </form>
          </div>
         
        </div>
      </div>
    </React.Fragment>
  );
};

export default withBrand(withLineLiff(withAuth(Register)));

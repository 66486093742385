import React, { useEffect, useState } from "react";

import { PostDetailAPI } from "services/interface";
import PostService from "services/post";
import useBrand from "stores/brand/useBrand";
import useUser from "stores/user/useUser";
import withBrand from "hoc/withBrand";
import { Link } from "react-router-dom";

const Catalogs = () => {
  const { brand } = useBrand();
  const { user, lineLiff } = useUser();
  const [posts, setPosts] = useState<PostDetailAPI[] | null>(null);

  useEffect(() => {
    PostService.getAll({ uuid: lineLiff.profile?.userId || "" }).then((res) => {
      setPosts(res.data);
    });
  }, []);

  return (
    <div className="px-4 py-4">
      <div className="grid grid-cols-3 gap-4">
        {posts?.map((post) => {
          return (
            <Link key={post.id} to={`/catalogdetail/${post.id}`}>
              <a>
                <div className="cursor-pointer">
                  <img src={post.pictures[0].image_url} />
                </div>
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default withBrand(Catalogs);

import axios from "axios";

const UserService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/user`,
});

interface userProps {
  uuid: string;
}

interface editUserProps {
  uuid: string;
  data: {
    name: string;
    email: string;
    birthdate: string;
    tel: string;
  };
}

const UserServices = {
  checkUser: ({ uuid }: userProps) =>
    UserService.get("", {
      headers: {
        Uuid: uuid,
      },
    }),
  register: ({ uuid }: userProps) =>
    UserService.post("/register", null, {
      headers: {
        Uuid: uuid,
      },
    }),
  edit: ({ uuid, data }: editUserProps) =>
    UserService.put("/profile", data, {
      headers: {
        Uuid: uuid,
      },
    }),
};

export default UserServices;

import { Helmet } from "react-helmet";
import React, { useEffect, useMemo, useState } from "react";
import withAuth from "hoc/admin/withAuth";
import withLineLiff from "hoc/admin/withLineLiff";
import withBrand from "hoc/withBrand";
import liff from "@line/liff";
import { useHistory } from "react-router-dom";

const Scan = () => {
  let history = useHistory();

  useEffect(() => {
    scanCodeV2();
  }, []);

  const scanCodeV2 = async () => {
    try {
      const result = await liff.scanCodeV2();
      let url = result.value as string;
      let urlSplit = url.split("/");
      if(urlSplit[urlSplit.length - 1].match("member-detail\\?memberNo=")) {
        history.push(urlSplit[urlSplit.length - 1])
      } else {
        console.log("not match")
      }
    } catch (error) {
      console.log("scanCodeV2", error);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Scan QR - Atmosph CRM</title>
        <meta name="description" content="Scan QR - Atmosph CRM" />
      </Helmet>
      <h3 id="result">Something is going to happen here...</h3>
    </React.Fragment>
  );
};

export default withBrand(withLineLiff(withAuth(Scan)));

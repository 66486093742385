import React, { useEffect } from "react";
import { FieldValues, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";

interface InputTextProps {
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors: {
    [x: string]: any;
  };
  name: string;
  required?: boolean;
  label?: string;
  errorMessage?: string;
  autoFocus?: boolean;
  defaultValue?: string;
}

const InputDate = ({
  register,
  watch,
  setValue,
  name,
  required,
  label,
  errors,
  errorMessage,
  autoFocus,
  defaultValue
}: InputTextProps) => {
  useEffect(() => {
    if(defaultValue) {
      setValue(name, defaultValue)
    }
  }, [])
  return (
    <React.Fragment>
      <div className="mb-4 relative">
        <input
          id={name}
          type="date"
          {...register(name, { required: required || false })}
          autoFocus={autoFocus}
          value={watch(name)?.substring(0, 10)}
        />
        {errors[name] && (
          <div className="text-left">
            <span className="text-red-400 ">{errorMessage}</span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default InputDate;

import { InputCheckBox, InputDate, InputSubmit } from "components/common/form";
import React, { useEffect } from "react";

import AdminServices from "services/admin";
import { Helmet } from "react-helmet";
import InputPassword from "components/common/form/InputPassword";
import InputPhoneNumber from "components/common/form/InputPhoneNumber";
import InputText from "components/common/form/InputText";
import Swal from "sweetalert2";
import UserServices from "services/user";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import useUser from "stores/user/useUser";
import withAuth from "hoc/withAuth";
import withBrand from "hoc/withBrand";
import withLineLiff from "hoc/withLineLiff";

const EditProfile = () => {
  const { lineLiff, user } = useUser();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  let history = useHistory();

  useEffect(() => {
    console.log(lineLiff);
  }, [lineLiff]);

  const onEditProfile = (data: any) => {
    data.birthdate = data.birthdate.substring(0, 10);
    UserServices.edit({ uuid: lineLiff.profile?.userId || "", data })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "แก้ไขข้อมูลสำเร็จ",
        }).then(() => {
          history.push("/profile");
        });
      })
      .catch((err) => {
        switch (err.response.status) {
          default:
            Swal.fire({
              icon: "error",
              title: err.response.status,
              text: `(${err.response.data.status_code})`,
            });
            break;
        }
      });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Edit Profile - Atmosph CRM</title>
        <meta name="description" content="Register - Atmosph CRM" />
      </Helmet>
      <div className="container mx-auto py-12">
        <div>
          <img
            className="w-32 h-32 object-cover rounded-full mx-auto"
            src={
              lineLiff.profile?.pictureUrl ||
              "https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
            }
            alt="profile_image"
          />
          <h1 className="text-2xl font-medium text-gray-900 title-font container pt-3 pb-3 mx-auto text-center md:text-left">
            {lineLiff.profile?.displayName}
          </h1>
        </div>
        <form onSubmit={handleSubmit(onEditProfile)}>
          <div className="px-7">
            <InputText
              register={register}
              watch={watch}
              setValue={setValue}
              errors={errors}
              name="name"
              required={true}
              label="ชื่อ"
              errorMessage="กรุณากรอกชื่อ"
              autoFocus={true}
              defaultValue={user?.profile?.name}
            />
            <InputText
              register={register}
              watch={watch}
              setValue={setValue}
              errors={errors}
              name="email"
              required={true}
              label="อีเมล"
              errorMessage="กรุณากรอกอีเมล"
              defaultValue={user?.profile?.email}
            />
            <InputDate
              register={register}
              watch={watch}
              setValue={setValue}
              errors={errors}
              name="birthdate"
              required={true}
              label="วันเกิด"
              errorMessage="กรุณาเลือกวันเกิด"
              defaultValue={user?.profile?.birthdate || ""}
            />
            <InputPhoneNumber
              register={register}
              watch={watch}
              setValue={setValue}
              errors={errors}
              name="tel"
              required={true}
              label="เบอร์โทร"
              errorMessage="กรุณากรอกเบอร์โทร"
              defaultValue={user?.profile?.tel}
            />
            <InputSubmit>ยืนยัน</InputSubmit>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default withBrand(withLineLiff(withAuth(EditProfile)));

import 'dayjs/locale/th'

import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { RedemtionLogAPI } from "services/interface";
import RedemtionModal from "components/redemtion/redemtionModal";
import dayjs from 'dayjs'
import useUser from "stores/user/useUser";
import withAuth from "hoc/withAuth";
import withBrand from "hoc/withBrand";
import withLineLiff from "hoc/withLineLiff";

export interface RewardModalInterface {
  reward: RedemtionLogAPI | null;
  isOpen: boolean;
  isRedeemLoading: boolean;
}

const Redemtion = () => {
  const { lineLiff, user } = useUser();

  const [rewardModal, setRewardModal] = useState<RewardModalInterface>({
    reward: null,
    isOpen: false,
    isRedeemLoading: false,
  });

  const onOpenModal = (index: number) => {
    setRewardModal({
      ...rewardModal,
      reward: user?.profile?.redemption_reward_logs ? user?.profile?.redemption_reward_logs[index] : null,
      isOpen: true,
    });
  };

  const onCloseModal = () => {
    setRewardModal({
      reward: null,
      isOpen: false,
      isRedeemLoading: false,
    });
  };

  let rewardModalProps = {
    rewardModal,
    onCloseModal,
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Redemtion - Atmosph CRM</title>
        <meta name="description" content="Redemtion - Atmosph CRM" />
      </Helmet>
      <div className="w-screen min-h-screen bg-gray-100">
        <div className="pt-1">
          <RedemtionModal {...rewardModalProps} />
            {user?.profile?.redemption_reward_logs.length !== 0 ? (
              <React.Fragment>
                {user?.profile?.redemption_reward_logs.map((redemption, key) => {
                  return (
                    <div
                      key={key}
                      className="w-11/12 bg-white rounded-lg mx-auto py-3 my-4"
                      onClick={() => onOpenModal(key)}
                    >
                      <div className="flex">
                        <div className="w-2/6 flex items-center justify-center">
                          <img src={redemption.reward.reward_image} className="md:w-3/6 w-5/6 h-auto" />
                        </div>
                        <div className="w-4/6 flex items-center">
                          <div>
                            <h2 className="text-2xl font-medium text-gray-900 title-font">
                              {redemption.reward.reward_name}
                            </h2>
                            <p className="leading-relaxed">
                              Detail : {redemption.reward.reward_description}
                            </p>
                            <p className="leading-relaxed text-sm text-gray-500">
                              แลกเมื่อ {dayjs(redemption.created_at).locale('th').add(543, 'year').format('DD MMMM YYYY')}
                            </p>
                            {console.log(redemption.created_at)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              <div className="m-8 bg-white rounded-xl h-24">
                <div className="h-full flex items-center justify-center">
                  <div className="text-2xl">
                    <p className="">No Redemption</p>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withBrand(withLineLiff(withAuth(Redemtion)));

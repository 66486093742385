import { Helmet } from "react-helmet";
import React from "react";
import useUser from "stores/user/useUser";
import withAuth from "hoc/withAuth";
import withBrand from "hoc/withBrand";
import withLineLiff from "hoc/withLineLiff";

const Profile = () => {
  const { lineLiff, user } = useUser();
  return (
    <React.Fragment>
      <Helmet>
        <title>Profile - Atmosph CRM</title>
        <meta name="description" content="Profile - Atmosph CRM" />
      </Helmet>

      {lineLiff.profile ? (
        <>
          <div className="p-8 w-full">
            <div className="w-full bg-white shadow-lg rounded-lg my-20">
              <div className="py-4 px-8">
                <div className="flex justify-center -mt-20 pb-5">
                  <img
                    className="w-32 h-32 object-cover rounded-full"
                    src={
                      lineLiff.profile?.pictureUrl ||
                      "https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                    }
                  />
                </div>
                <div>
                  <h2 className="text-subgray-dark text-3xl font-semibold text-center">
                    {lineLiff.profile.displayName}
                  </h2>
                </div>
              </div>
              <div className="bg-gray-50 rounded-b-lg py-4 px-8">
                <div className="flex items-start">
                  <div>
                    <p className="text-subgray text-base font-semibold">
                      Tier : {user?.profile?.tier.tier_name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </>
      ) : (
        <div>loading</div>
      )}
    </React.Fragment>
  );
};

export default withBrand(withLineLiff(withAuth(Profile)));

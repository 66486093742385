import { Config, ConfigInterface } from "route.config";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AdminAPI } from "services/interface";
import AdminServices from "services/admin";
import useAdmin from "stores/admin/useAdmin";

const withAuth = (WrappedComponent: any) => (props: any) => {
  const [isShow, setIsShow] = useState(false);
  const { admin, lineLiff, FetchingAdminDone } = useAdmin();
  let location = useLocation();
  let pathname = useMemo(() => {
    if (location.pathname.substr(location.pathname.length - 1) === "/") {
      return location.pathname.substr(0, location.pathname.length - 1);
    } else {
      return location.pathname;
    }
  }, []);
  let history = useHistory();
  let routesConfig: ConfigInterface = Config;
  let routeConfig = routesConfig[pathname];

  useEffect(() => {
    if (routeConfig) {
      if (routeConfig.isAuth) {
        if (!admin?.profile) {
          console.log("auth admin work at", pathname);
          console.log("LINE LIFF: ", lineLiff);
          console.log("ADMIN: ", admin);
          AdminServices.checkAdmin({ uuid: lineLiff.profile?.userId || "" })
            .then((res) => {
              let admin: AdminAPI = res.data;
              FetchingAdminDone(admin);
              if (pathname === "/admin/login") {
                history.push("/admin/profile");
              } else {
                setIsShow(true);
              }
            })
            .catch((err) => {
              if (err.response && err.response.status) {
                switch (err.response.status) {
                  case 401:
                    FetchingAdminDone(null);
                    if (pathname !== "/admin/login") {
                      history.push("/admin/login");
                    }
                    break;
                  default:
                    break;
                }
              }
              setIsShow(true);
            });
        } else {
          if (pathname === "/admin/login") {
            history.push("/admin/profile");
          } else {
            setIsShow(true);
          }
        }
      } else {
        setIsShow(true);
      }
    } else {
      setIsShow(true);
    }
  }, []);

  if (!isShow) {
    return <div>Loading...</div>;
  }
  return <WrappedComponent {...props} />;
};

export default withAuth;

import Button from "components/common/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Services as services } from "service.config";
import useBrand from "stores/brand/useBrand";
import useUser from "stores/user/useUser";
import withAuth from "hoc/withAuth";
import withBrand from "hoc/withBrand";
import withLineLiff from "hoc/withLineLiff";

const Services = () => {
  const { brand } = useBrand();
  return (
    <>
      <Helmet>
        <title>Services - Atmosph CRM</title>
        <meta name="description" content="Services - Atmosph CRM" />
        <div>Services</div>
      </Helmet>
      <div className="bg-gray-100 w-screen ">
        <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-12">
          <div className="grid grid-cols-2 gap-6">
            {services.map((service, key) => {
              return (
                <Link key={key} to={service.path}>
                  <div className="w-full bg-indigo-400 rounded-lg sahdow-lg p-12 pb-8 flex flex-col justify-center items-center" style={{backgroundColor: brand?.brand?.primary_color}}>
                    <div className="mb-4">
                      <FontAwesomeIcon
                        icon={service.icon}
                        className="text-4xl text-white"
                      />
                    </div>
                    <div className="text-center">
                      <p className="text-xl text-white font-bold mb-2">
                        {service.label}
                      </p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </section>
      </div>
    </>
  );
};

export default withBrand(withLineLiff(withAuth(Services)));

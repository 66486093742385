import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { TierAPI } from "services/interface";
import TierServices from "services/tier";
import useUser from "stores/user/useUser";
import withAuth from "hoc/withAuth";
import withBrand from "hoc/withBrand";
import withLineLiff from "hoc/withLineLiff";

const Tier = () => {
  const [step, setStep] = useState(0);
  const { lineLiff, user } = useUser();
  const [tiers, setTiers] = useState<TierAPI[] | null>(null);
  const [tier, setTier] = useState<TierAPI | null>();

  useEffect(() => {
    TierServices.getAll({ uuid: lineLiff.profile?.userId || "" })
      .then((res) => {
        setTiers(res.data);
        setTier(res.data[0]);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const selectedTier = (key: number, tier: TierAPI) => {
    setStep(key);
    setTier(tier);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Tier - Atmosph CRM</title>
        <meta name="description" content="Tier - Atmosph CRM" />
      </Helmet>
      <div className="w-screen h-screen bg-gray-100">
        {lineLiff.profile ? (
          <>
            <div className="px-8 py-1 w-full">
              <div className="w-full bg-white shadow-lg rounded-lg my-20">
                <div className="py-4 px-8">
                  <div className="flex justify-center -mt-20 pb-5">
                    <img
                      className="w-32 h-32 object-cover rounded-full"
                      src={
                        lineLiff.profile?.pictureUrl ||
                        "https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                      }
                    />
                  </div>
                  <div>
                    <h2 className="text-subgray-dark text-3xl font-semibold text-center">
                      {lineLiff.profile.displayName}
                    </h2>
                    <p className="text-subgray text-base font-semibold">
                      Tier : {user?.profile?.tier.tier_name}
                    </p>
                  </div>
                </div>
                <div>
                  {tiers && tier ? (
                    <React.Fragment>
                      <div className="flex text-center bg-white">
                        {tiers.map((tier, key) => {
                          let first = key === 0;
                          let last = key === tiers.length - 1;
                          return (
                            <React.Fragment>
                              <div
                                className={`flex-1 group border-indigo-500 py-3 rounded-t-lg 
                                    ${
                                      step === key
                                        ? first
                                          ? "text-indigo-500 bg-white border-indigo-500 border-l-0 border-r border-t border-b-0"
                                          : last
                                          ? "text-indigo-500 bg-white border-indigo-500 border-l border-r-0 border-t border-b-0"
                                          : "text-indigo-500 bg-white border-indigo-500 border-l border-r border-t border-b-0"
                                        : "text-gray-400 border-b bg-gray-100"
                                    }
                                    `}
                              >
                                <div onClick={() => selectedTier(key, tier)}>
                                  <span className="pb-1">{tier.tier_name}</span>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                      <div className="p-4">
                        <div className="pb-2">
                          <p>Detail</p>
                          <p> Point : {tier.tier_point} </p>
                          <p>
                            {" "}
                            Multiplier Point : {tier.tier_multiplier_point}{" "}
                          </p>
                          <br />
                          <p>Reward</p>
                        </div>
                        <div>
                          {tier.benefits.map((benefits, key) => {
                            return (
                              <React.Fragment>
                                <div className="flex py-2 border-t border-gray-200">
                                  <div className="w-1/5">
                                    <img
                                      className="w-full h-auto object-cover rounded-full m-auto"
                                      src={benefits.icon}
                                    />
                                  </div>
                                  <div className="w-4/5 my-auto pl-3">
                                    <p> Title : {benefits.title} </p>
                                    <p> Detail : {benefits.detail} </p>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div>load</div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>loading</div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withBrand(withLineLiff(withAuth(Tier)));
